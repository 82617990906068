<template>
    <v-data-table
      dense
      locale="ru"
      item-key="id"
      single-line
      sort-by="sdate"
      :headers="resultsHeaders"
      :items="dataGroupped"
      :items-per-page="100"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 200, -1] }"
      :hide-default-footer="false"
      class="elevation-1 accountsTable"
    >
      <template v-slot:item="{ item }">
        <tr style="height: 120px;" @click="clickHandler(item)">
          <td class="pl-2">{{ item.tvchannel }}</td>
          <td class="pa-1">{{ item.event }}</td>
          <td class="pa-1" v-html="dtStart(item)"></td>
          <td class="pa-1">{{ item.subject }}</td>
          <td class="pa-1" style="min-width: 160px;" v-html="personsStr(item)"></td>
          <td class="pa-1" v-html="tagsStr(item)"></td>
          <td class="pa-1" style="min-width: 110px;"><v-layout justify-center>{{ item.contacts.toLocaleString() }}</v-layout></td>
          <td class="pa-1" :style="getProgStyle(item)"><v-layout justify-center>{{ item.appeal }}%</v-layout></td>
        </tr>
      </template>
    </v-data-table>
</template>

<script>
import moment from "moment";
import { CONSTS } from "../services/constants.js";
export default {
  props: {
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({
    resultsHeaders: [
      {
        text: "Канал",
        value: "tvchannel",
      },
      {
        text: "Передача",
        value: "event",
      },
      {
        text: "Дата",
        value: "sdate",
      },
/*       {
        text: "Продолжительность",
        value: "duration",
        width: "130px",
      }, */
      {
        text: "Сюжет",
        value: "subject",
      },
      {
        text: "Персоны",
        value: "persons",
      },
      {
        text: "Темы",
        value: "tags",
        width: "140px",
      },
      {
        text: "Контакты",
        value: "contacts",
        width: "100px",
      },
      {
        text: "Привлекательность",
        value: "appeal",
        width: "100px",
      },
    ],
  }),

  methods: {
    clickHandler(item) {
      console.log(item)
      this.$emit("rowClick", item);
    },
    dtStart(item) {
      return `${moment(item.sdate).locale("ru").format("LL")}<br>${moment(item.sdate).locale("ru").format("HH:mm:ss")}, ${item.duration} сек.`;
    },
    personsStr(item) {
      var maxLines = 4;
      let str = item.persons.slice(0, maxLines).join("<br>").split(" ").join("&nbsp;");
      if (item.persons.length > maxLines) str += `<br><span class="aLink">всего: ${item.persons.length}</span>`;
      return str;
    },
    tagsStr(item) {
      var maxLines = 4;
      let str = item.tags.slice(0, maxLines).join("<br>").split(" ").join("&nbsp;");
      if (item.tags.length > maxLines) str += `<br><span class="aLink">всего: ${item.tags.length}</span>`;
      return str;
    },
    getBackgroundForProg(item) {
      if (item.appeal <= -80)
        return CONSTS.TV_COMPARE_SLIDER_COLORS[0];
      if (item.appeal <= -10)
        return CONSTS.TV_COMPARE_SLIDER_COLORS[1];
      if (item.appeal <= 10)
        return CONSTS.TV_COMPARE_SLIDER_COLORS[2];
      if (item.appeal <= 80)
        return CONSTS.TV_COMPARE_SLIDER_COLORS[3];
      return CONSTS.TV_COMPARE_SLIDER_COLORS[4];
    },
    getProgStyle(item) {
      const color = this.getBackgroundForProg(item);
      return `background-color: ${color}`;
    }    
  },
  computed: {
    dataGroupped() {
      const data = {}, persons = {}, tags = {}

      for (const item of this.data) {
        if (!(item.id in data)) data[item.id] = item;
        if (item.type === "persona") {
          if (!(item.id in persons)) persons[item.id] = {};
          persons[item.id][item.name] = 1;
        }
        if (item.type === "tag") {
          if (!(item.id in tags)) tags[item.id] = {};
          tags[item.id][item.name] = 1;
        }
      }
      const results = [];
      for (const item_ of Object.values(data)) {
        const item = {...item_};
        item.tags = [];
        item.persons = [];
        if (item.id in tags) item.tags = [...Object.keys(tags[item.id])];
        if (item.id in persons) item.persons = [...Object.keys(persons[item.id])];
        results.push(item);
      }
      //console.log(results);
      return results;
    },
  },

};
</script>

<style>
  .aLink {
      color: blue;
      text-decoration: underline;
      cursor: pointer;
  }
</style>
